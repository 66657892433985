import React from "react"
import { Box, Button, Flex, Link as LinkExt, Stack } from "@chakra-ui/react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import "../utils/slick.css"
import "../utils/slick-theme.css"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  HomeSlideshow,
  HighlightedTeam,
  InsideInstitute,
  NewsGrid,
  HomeQuickLinks,
  Seo,
} from "../components/elements/"
import {
  IntroText,
  SectionHeading,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const IndexPage = ({ data }) => {
  const page = data.datoCmsCancerHome

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      {/* Hero-SlideShow */}
      <HomeSlideshow page={page} />

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container>
            <Box width="full">
              <IntroText>{data.datoCmsCancerHome.introContent}</IntroText>
            </Box>
            <Stack direction={["column", "column", "row"]} spacing={12}>
              <Box width={["full", "full", "1 / 2"]}>
                <GatsbyImage
                  image={data.datoCmsCancerHome.introImage.gatsbyImageData}
                />
              </Box>
              <Box width={["full", "full", "1 / 2"]} alignSelf="center">
                <Box
                  dangerouslySetInnerHTML={{
                    __html:
                      data.datoCmsCancerHome.contentNode.childMarkdownRemark
                        .html,
                  }}
                />

                <Link to="/about-us" style={{ textDecoration: "none" }}>
                  <Button as="span" variant="outline" colorScheme="blue">
                    About WVU Cancer Institute
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Container>
        </Section>

        {/* Inside The Institute */}
        <Box pt={0} pb={[9, 9, 12, 16]}>
          <Container>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box width="full">
                <SectionHeading textAlign="center" id="inside">
                  Inside The Institute
                </SectionHeading>

                <InsideInstitute />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* HighlightedTeam */}
        <Section id="teams" bg="lightgray">
          <Container>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box width="full">
                <SectionHeading textAlign="center" id="highlighted-teams">
                  Highlighted Teams
                </SectionHeading>

                <HighlightedTeam />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* HomeQuickLinks */}
        <HomeQuickLinks />

        {/* News */}
        <Section id="news">
          <Container>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full"], textAlign: "center" }}>
                <SectionHeading>News</SectionHeading>
              </Box>
              <NewsGrid />

              <Box sx={{ pt: 4, textAlign: "right", width: ["full"] }}>
                <LinkExt href="https://wvumedicine.org/news-feed/?sec=cancer-institute">
                  See More Stories <FontAwesomeIcon icon={faChevronRight} />
                </LinkExt>
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    datoCmsCancerHome {
      title
      slug
      introContent
      contentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      introImage {
        gatsbyImageData(width: 800)
      }
      slideshow {
        buttonText
        link
        id
        slideContent
        image {
          gatsbyImageData(imgixParams: { fit: "crop", crop: "focalpoint" })
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`
